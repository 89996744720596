import { fullUnicode } from '../../fullUnicode.js';
import { oneof } from '../../oneof.js';
import { mapToConstant } from '../../mapToConstant.js';
import { safeCharCodeAt, safeNumberToString, encodeURIComponent } from '../../../utils/globals.js';
const safeStringFromCharCode = String.fromCharCode;
const lowerCaseMapper = { num: 26, build: (v) => safeStringFromCharCode(v + 0x61) };
const upperCaseMapper = { num: 26, build: (v) => safeStringFromCharCode(v + 0x41) };
const numericMapper = { num: 10, build: (v) => safeStringFromCharCode(v + 0x30) };
function percentCharArbMapper(c) {
    const encoded = encodeURIComponent(c);
    return c !== encoded ? encoded : `%${safeNumberToString(safeCharCodeAt(c, 0), 16)}`;
}
function percentCharArbUnmapper(value) {
    if (typeof value !== 'string') {
        throw new Error('Unsupported');
    }
    const decoded = decodeURIComponent(value);
    return decoded;
}
const percentCharArb = fullUnicode().map(percentCharArbMapper, percentCharArbUnmapper);
export const buildLowerAlphaArbitrary = (others) => mapToConstant(lowerCaseMapper, { num: others.length, build: (v) => others[v] });
export const buildLowerAlphaNumericArbitrary = (others) => mapToConstant(lowerCaseMapper, numericMapper, { num: others.length, build: (v) => others[v] });
export const buildAlphaNumericArbitrary = (others) => mapToConstant(lowerCaseMapper, upperCaseMapper, numericMapper, { num: others.length, build: (v) => others[v] });
export const buildAlphaNumericPercentArbitrary = (others) => oneof({ weight: 10, arbitrary: buildAlphaNumericArbitrary(others) }, { weight: 1, arbitrary: percentCharArb });
