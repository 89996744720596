import { safePush } from '../../../utils/globals.js';
const dangerousStrings = [
    '__defineGetter__',
    '__defineSetter__',
    '__lookupGetter__',
    '__lookupSetter__',
    '__proto__',
    'constructor',
    'hasOwnProperty',
    'isPrototypeOf',
    'propertyIsEnumerable',
    'toLocaleString',
    'toString',
    'valueOf',
    'apply',
    'arguments',
    'bind',
    'call',
    'caller',
    'length',
    'name',
    'prototype',
    'key',
    'ref',
];
function computeCandidateString(dangerous, charArbitrary, stringSplitter) {
    let candidate;
    try {
        candidate = stringSplitter(dangerous);
    }
    catch (err) {
        return undefined;
    }
    for (const entry of candidate) {
        if (!charArbitrary.canShrinkWithoutContext(entry)) {
            return undefined;
        }
    }
    return candidate;
}
export function createSlicesForString(charArbitrary, stringSplitter) {
    const slicesForString = [];
    for (const dangerous of dangerousStrings) {
        const candidate = computeCandidateString(dangerous, charArbitrary, stringSplitter);
        if (candidate !== undefined) {
            safePush(slicesForString, candidate);
        }
    }
    return slicesForString;
}
