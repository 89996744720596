"use strict";
// automatically generated by the FlatBuffers compiler, do not modify
Object.defineProperty(exports, "__esModule", { value: true });
exports.Unit = void 0;
/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */
var Unit;
(function (Unit) {
    Unit[Unit["undefined"] = 0] = "undefined";
    Unit[Unit["celsius"] = 1] = "celsius";
    Unit[Unit["centimetre"] = 2] = "centimetre";
    Unit[Unit["cubic_metre_per_cubic_metre"] = 3] = "cubic_metre_per_cubic_metre";
    Unit[Unit["cubic_metre_per_second"] = 4] = "cubic_metre_per_second";
    Unit[Unit["degree_direction"] = 5] = "degree_direction";
    Unit[Unit["dimensionless_integer"] = 6] = "dimensionless_integer";
    Unit[Unit["dimensionless"] = 7] = "dimensionless";
    Unit[Unit["european_air_quality_index"] = 8] = "european_air_quality_index";
    Unit[Unit["fahrenheit"] = 9] = "fahrenheit";
    Unit[Unit["feet"] = 10] = "feet";
    Unit[Unit["fraction"] = 11] = "fraction";
    Unit[Unit["gdd_celsius"] = 12] = "gdd_celsius";
    Unit[Unit["geopotential_metre"] = 13] = "geopotential_metre";
    Unit[Unit["grains_per_cubic_metre"] = 14] = "grains_per_cubic_metre";
    Unit[Unit["gram_per_kilogram"] = 15] = "gram_per_kilogram";
    Unit[Unit["hectopascal"] = 16] = "hectopascal";
    Unit[Unit["hours"] = 17] = "hours";
    Unit[Unit["inch"] = 18] = "inch";
    Unit[Unit["iso8601"] = 19] = "iso8601";
    Unit[Unit["joule_per_kilogram"] = 20] = "joule_per_kilogram";
    Unit[Unit["kelvin"] = 21] = "kelvin";
    Unit[Unit["kilopascal"] = 22] = "kilopascal";
    Unit[Unit["kilogram_per_square_metre"] = 23] = "kilogram_per_square_metre";
    Unit[Unit["kilometres_per_hour"] = 24] = "kilometres_per_hour";
    Unit[Unit["knots"] = 25] = "knots";
    Unit[Unit["megajoule_per_square_metre"] = 26] = "megajoule_per_square_metre";
    Unit[Unit["metre_per_second_not_unit_converted"] = 27] = "metre_per_second_not_unit_converted";
    Unit[Unit["metre_per_second"] = 28] = "metre_per_second";
    Unit[Unit["metre"] = 29] = "metre";
    Unit[Unit["micrograms_per_cubic_metre"] = 30] = "micrograms_per_cubic_metre";
    Unit[Unit["miles_per_hour"] = 31] = "miles_per_hour";
    Unit[Unit["millimetre"] = 32] = "millimetre";
    Unit[Unit["pascal"] = 33] = "pascal";
    Unit[Unit["per_second"] = 34] = "per_second";
    Unit[Unit["percentage"] = 35] = "percentage";
    Unit[Unit["seconds"] = 36] = "seconds";
    Unit[Unit["unix_time"] = 37] = "unix_time";
    Unit[Unit["us_air_quality_index"] = 38] = "us_air_quality_index";
    Unit[Unit["watt_per_square_metre"] = 39] = "watt_per_square_metre";
    Unit[Unit["wmo_code"] = 40] = "wmo_code";
})(Unit || (exports.Unit = Unit = {}));
