let globalParameters = {};
export function configureGlobal(parameters) {
    globalParameters = parameters;
}
export function readConfigureGlobal() {
    return globalParameters;
}
export function resetConfigureGlobal() {
    globalParameters = {};
}
