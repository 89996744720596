"use strict";
// automatically generated by the FlatBuffers compiler, do not modify
Object.defineProperty(exports, "__esModule", { value: true });
exports.Aggregation = void 0;
/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */
var Aggregation;
(function (Aggregation) {
    Aggregation[Aggregation["none"] = 0] = "none";
    Aggregation[Aggregation["minimum"] = 1] = "minimum";
    Aggregation[Aggregation["maximum"] = 2] = "maximum";
    Aggregation[Aggregation["mean"] = 3] = "mean";
    Aggregation[Aggregation["p10"] = 4] = "p10";
    Aggregation[Aggregation["p25"] = 5] = "p25";
    Aggregation[Aggregation["median"] = 6] = "median";
    Aggregation[Aggregation["p75"] = 7] = "p75";
    Aggregation[Aggregation["p90"] = 8] = "p90";
    Aggregation[Aggregation["dominant"] = 9] = "dominant";
    Aggregation[Aggregation["sum"] = 10] = "sum";
})(Aggregation || (exports.Aggregation = Aggregation = {}));
