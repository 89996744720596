export function buildStableArbitraryGeneratorCache(isEqual) {
    const previousCallsPerBuilder = new Map();
    return function stableArbitraryGeneratorCache(builder, args) {
        const entriesForBuilder = previousCallsPerBuilder.get(builder);
        if (entriesForBuilder === undefined) {
            const newValue = builder(...args);
            previousCallsPerBuilder.set(builder, [{ args, value: newValue }]);
            return newValue;
        }
        const safeEntriesForBuilder = entriesForBuilder;
        for (const entry of safeEntriesForBuilder) {
            if (isEqual(args, entry.args)) {
                return entry.value;
            }
        }
        const newValue = builder(...args);
        safeEntriesForBuilder.push({ args, value: newValue });
        return newValue;
    };
}
export function naiveIsEqual(v1, v2) {
    if (v1 !== null && typeof v1 === 'object' && v2 !== null && typeof v2 === 'object') {
        if (Array.isArray(v1)) {
            if (!Array.isArray(v2))
                return false;
            if (v1.length !== v2.length)
                return false;
        }
        else if (Array.isArray(v2)) {
            return false;
        }
        if (Object.keys(v1).length !== Object.keys(v2).length) {
            return false;
        }
        for (const index in v1) {
            if (!(index in v2)) {
                return false;
            }
            if (!naiveIsEqual(v1[index], v2[index])) {
                return false;
            }
        }
        return true;
    }
    else {
        return Object.is(v1, v2);
    }
}
