"use strict";
// automatically generated by the FlatBuffers compiler, do not modify
Object.defineProperty(exports, "__esModule", { value: true });
exports.Model = void 0;
/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */
var Model;
(function (Model) {
    Model[Model["undefined"] = 0] = "undefined";
    Model[Model["best_match"] = 1] = "best_match";
    Model[Model["gfs_seamless"] = 2] = "gfs_seamless";
    Model[Model["gfs_global"] = 3] = "gfs_global";
    Model[Model["gfs_hrrr"] = 4] = "gfs_hrrr";
    Model[Model["meteofrance_seamless"] = 5] = "meteofrance_seamless";
    Model[Model["meteofrance_arpege_seamless"] = 6] = "meteofrance_arpege_seamless";
    Model[Model["meteofrance_arpege_world"] = 7] = "meteofrance_arpege_world";
    Model[Model["meteofrance_arpege_europe"] = 8] = "meteofrance_arpege_europe";
    Model[Model["meteofrance_arome_seamless"] = 9] = "meteofrance_arome_seamless";
    Model[Model["meteofrance_arome_france"] = 10] = "meteofrance_arome_france";
    Model[Model["meteofrance_arome_france_hd"] = 11] = "meteofrance_arome_france_hd";
    Model[Model["jma_seamless"] = 12] = "jma_seamless";
    Model[Model["jma_msm"] = 13] = "jma_msm";
    Model[Model["jms_gsm"] = 14] = "jms_gsm";
    Model[Model["jma_gsm"] = 15] = "jma_gsm";
    Model[Model["gem_seamless"] = 16] = "gem_seamless";
    Model[Model["gem_global"] = 17] = "gem_global";
    Model[Model["gem_regional"] = 18] = "gem_regional";
    Model[Model["gem_hrdps_continental"] = 19] = "gem_hrdps_continental";
    Model[Model["icon_seamless"] = 20] = "icon_seamless";
    Model[Model["icon_global"] = 21] = "icon_global";
    Model[Model["icon_eu"] = 22] = "icon_eu";
    Model[Model["icon_d2"] = 23] = "icon_d2";
    Model[Model["ecmwf_ifs04"] = 24] = "ecmwf_ifs04";
    Model[Model["metno_nordic"] = 25] = "metno_nordic";
    Model[Model["era5_seamless"] = 26] = "era5_seamless";
    Model[Model["era5"] = 27] = "era5";
    Model[Model["cerra"] = 28] = "cerra";
    Model[Model["era5_land"] = 29] = "era5_land";
    Model[Model["ecmwf_ifs"] = 30] = "ecmwf_ifs";
    Model[Model["gwam"] = 31] = "gwam";
    Model[Model["ewam"] = 32] = "ewam";
    Model[Model["glofas_seamless_v3"] = 33] = "glofas_seamless_v3";
    Model[Model["glofas_forecast_v3"] = 34] = "glofas_forecast_v3";
    Model[Model["glofas_consolidated_v3"] = 35] = "glofas_consolidated_v3";
    Model[Model["glofas_seamless_v4"] = 36] = "glofas_seamless_v4";
    Model[Model["glofas_forecast_v4"] = 37] = "glofas_forecast_v4";
    Model[Model["glofas_consolidated_v4"] = 38] = "glofas_consolidated_v4";
    Model[Model["gfs025"] = 39] = "gfs025";
    Model[Model["gfs05"] = 40] = "gfs05";
    Model[Model["CMCC_CM2_VHR4"] = 41] = "CMCC_CM2_VHR4";
    Model[Model["FGOALS_f3_H_highresSST"] = 42] = "FGOALS_f3_H_highresSST";
    Model[Model["FGOALS_f3_H"] = 43] = "FGOALS_f3_H";
    Model[Model["HiRAM_SIT_HR"] = 44] = "HiRAM_SIT_HR";
    Model[Model["MRI_AGCM3_2_S"] = 45] = "MRI_AGCM3_2_S";
    Model[Model["EC_Earth3P_HR"] = 46] = "EC_Earth3P_HR";
    Model[Model["MPI_ESM1_2_XR"] = 47] = "MPI_ESM1_2_XR";
    Model[Model["NICAM16_8S"] = 48] = "NICAM16_8S";
    Model[Model["cams_europe"] = 49] = "cams_europe";
    Model[Model["cams_global"] = 50] = "cams_global";
    Model[Model["cfsv2"] = 51] = "cfsv2";
    Model[Model["era5_ocean"] = 52] = "era5_ocean";
    Model[Model["cma_grapes_global"] = 53] = "cma_grapes_global";
    Model[Model["bom_access_global"] = 54] = "bom_access_global";
    Model[Model["bom_access_global_ensemble"] = 55] = "bom_access_global_ensemble";
    Model[Model["arpae_cosmo_seamless"] = 56] = "arpae_cosmo_seamless";
    Model[Model["arpae_cosmo_2i"] = 57] = "arpae_cosmo_2i";
    Model[Model["arpae_cosmo_2i_ruc"] = 58] = "arpae_cosmo_2i_ruc";
    Model[Model["arpae_cosmo_5m"] = 59] = "arpae_cosmo_5m";
    Model[Model["ecmwf_ifs025"] = 60] = "ecmwf_ifs025";
    Model[Model["ecmwf_aifs025"] = 61] = "ecmwf_aifs025";
    Model[Model["gfs013"] = 62] = "gfs013";
    Model[Model["gfs_graphcast025"] = 63] = "gfs_graphcast025";
})(Model || (exports.Model = Model = {}));
