"use strict";
// automatically generated by the FlatBuffers compiler, do not modify
Object.defineProperty(exports, "__esModule", { value: true });
exports.Variable = void 0;
/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */
var Variable;
(function (Variable) {
    Variable[Variable["undefined"] = 0] = "undefined";
    Variable[Variable["apparent_temperature"] = 1] = "apparent_temperature";
    Variable[Variable["cape"] = 2] = "cape";
    Variable[Variable["cloud_cover"] = 3] = "cloud_cover";
    Variable[Variable["cloud_cover_high"] = 4] = "cloud_cover_high";
    Variable[Variable["cloud_cover_low"] = 5] = "cloud_cover_low";
    Variable[Variable["cloud_cover_mid"] = 6] = "cloud_cover_mid";
    Variable[Variable["daylight_duration"] = 7] = "daylight_duration";
    Variable[Variable["dew_point"] = 8] = "dew_point";
    Variable[Variable["diffuse_radiation"] = 9] = "diffuse_radiation";
    Variable[Variable["diffuse_radiation_instant"] = 10] = "diffuse_radiation_instant";
    Variable[Variable["direct_normal_irradiance"] = 11] = "direct_normal_irradiance";
    Variable[Variable["direct_normal_irradiance_instant"] = 12] = "direct_normal_irradiance_instant";
    Variable[Variable["direct_radiation"] = 13] = "direct_radiation";
    Variable[Variable["direct_radiation_instant"] = 14] = "direct_radiation_instant";
    Variable[Variable["et0_fao_evapotranspiration"] = 15] = "et0_fao_evapotranspiration";
    Variable[Variable["evapotranspiration"] = 16] = "evapotranspiration";
    Variable[Variable["freezing_level_height"] = 17] = "freezing_level_height";
    Variable[Variable["growing_degree_days"] = 18] = "growing_degree_days";
    Variable[Variable["is_day"] = 19] = "is_day";
    Variable[Variable["latent_heat_flux"] = 20] = "latent_heat_flux";
    Variable[Variable["leaf_wetness_probability"] = 21] = "leaf_wetness_probability";
    Variable[Variable["lifted_index"] = 22] = "lifted_index";
    Variable[Variable["lightning_potential"] = 23] = "lightning_potential";
    Variable[Variable["precipitation"] = 24] = "precipitation";
    Variable[Variable["precipitation_hours"] = 25] = "precipitation_hours";
    Variable[Variable["precipitation_probability"] = 26] = "precipitation_probability";
    Variable[Variable["pressure_msl"] = 27] = "pressure_msl";
    Variable[Variable["rain"] = 28] = "rain";
    Variable[Variable["relative_humidity"] = 29] = "relative_humidity";
    Variable[Variable["runoff"] = 30] = "runoff";
    Variable[Variable["sensible_heat_flux"] = 31] = "sensible_heat_flux";
    Variable[Variable["shortwave_radiation"] = 32] = "shortwave_radiation";
    Variable[Variable["shortwave_radiation_instant"] = 33] = "shortwave_radiation_instant";
    Variable[Variable["showers"] = 34] = "showers";
    Variable[Variable["snow_depth"] = 35] = "snow_depth";
    Variable[Variable["snow_height"] = 36] = "snow_height";
    Variable[Variable["snowfall"] = 37] = "snowfall";
    Variable[Variable["snowfall_height"] = 38] = "snowfall_height";
    Variable[Variable["snowfall_water_equivalent"] = 39] = "snowfall_water_equivalent";
    Variable[Variable["sunrise"] = 40] = "sunrise";
    Variable[Variable["sunset"] = 41] = "sunset";
    Variable[Variable["soil_moisture"] = 42] = "soil_moisture";
    Variable[Variable["soil_moisture_index"] = 43] = "soil_moisture_index";
    Variable[Variable["soil_temperature"] = 44] = "soil_temperature";
    Variable[Variable["surface_pressure"] = 45] = "surface_pressure";
    Variable[Variable["surface_temperature"] = 46] = "surface_temperature";
    Variable[Variable["temperature"] = 47] = "temperature";
    Variable[Variable["terrestrial_radiation"] = 48] = "terrestrial_radiation";
    Variable[Variable["terrestrial_radiation_instant"] = 49] = "terrestrial_radiation_instant";
    Variable[Variable["total_column_integrated_water_vapour"] = 50] = "total_column_integrated_water_vapour";
    Variable[Variable["updraft"] = 51] = "updraft";
    Variable[Variable["uv_index"] = 52] = "uv_index";
    Variable[Variable["uv_index_clear_sky"] = 53] = "uv_index_clear_sky";
    Variable[Variable["vapour_pressure_deficit"] = 54] = "vapour_pressure_deficit";
    Variable[Variable["visibility"] = 55] = "visibility";
    Variable[Variable["weather_code"] = 56] = "weather_code";
    Variable[Variable["wind_direction"] = 57] = "wind_direction";
    Variable[Variable["wind_gusts"] = 58] = "wind_gusts";
    Variable[Variable["wind_speed"] = 59] = "wind_speed";
    Variable[Variable["vertical_velocity"] = 60] = "vertical_velocity";
    Variable[Variable["geopotential_height"] = 61] = "geopotential_height";
    Variable[Variable["wet_bulb_temperature"] = 62] = "wet_bulb_temperature";
    Variable[Variable["river_discharge"] = 63] = "river_discharge";
    Variable[Variable["wave_height"] = 64] = "wave_height";
    Variable[Variable["wave_period"] = 65] = "wave_period";
    Variable[Variable["wave_direction"] = 66] = "wave_direction";
    Variable[Variable["wind_wave_height"] = 67] = "wind_wave_height";
    Variable[Variable["wind_wave_period"] = 68] = "wind_wave_period";
    Variable[Variable["wind_wave_peak_period"] = 69] = "wind_wave_peak_period";
    Variable[Variable["wind_wave_direction"] = 70] = "wind_wave_direction";
    Variable[Variable["swell_wave_height"] = 71] = "swell_wave_height";
    Variable[Variable["swell_wave_period"] = 72] = "swell_wave_period";
    Variable[Variable["swell_wave_peak_period"] = 73] = "swell_wave_peak_period";
    Variable[Variable["swell_wave_direction"] = 74] = "swell_wave_direction";
    Variable[Variable["pm10"] = 75] = "pm10";
    Variable[Variable["pm2p5"] = 76] = "pm2p5";
    Variable[Variable["dust"] = 77] = "dust";
    Variable[Variable["aerosol_optical_depth"] = 78] = "aerosol_optical_depth";
    Variable[Variable["carbon_monoxide"] = 79] = "carbon_monoxide";
    Variable[Variable["nitrogen_dioxide"] = 80] = "nitrogen_dioxide";
    Variable[Variable["ammonia"] = 81] = "ammonia";
    Variable[Variable["ozone"] = 82] = "ozone";
    Variable[Variable["sulphur_dioxide"] = 83] = "sulphur_dioxide";
    Variable[Variable["alder_pollen"] = 84] = "alder_pollen";
    Variable[Variable["birch_pollen"] = 85] = "birch_pollen";
    Variable[Variable["grass_pollen"] = 86] = "grass_pollen";
    Variable[Variable["mugwort_pollen"] = 87] = "mugwort_pollen";
    Variable[Variable["olive_pollen"] = 88] = "olive_pollen";
    Variable[Variable["ragweed_pollen"] = 89] = "ragweed_pollen";
    Variable[Variable["european_aqi"] = 90] = "european_aqi";
    Variable[Variable["european_aqi_pm2p5"] = 91] = "european_aqi_pm2p5";
    Variable[Variable["european_aqi_pm10"] = 92] = "european_aqi_pm10";
    Variable[Variable["european_aqi_nitrogen_dioxide"] = 93] = "european_aqi_nitrogen_dioxide";
    Variable[Variable["european_aqi_ozone"] = 94] = "european_aqi_ozone";
    Variable[Variable["european_aqi_sulphur_dioxide"] = 95] = "european_aqi_sulphur_dioxide";
    Variable[Variable["us_aqi"] = 96] = "us_aqi";
    Variable[Variable["us_aqi_pm2p5"] = 97] = "us_aqi_pm2p5";
    Variable[Variable["us_aqi_pm10"] = 98] = "us_aqi_pm10";
    Variable[Variable["us_aqi_nitrogen_dioxide"] = 99] = "us_aqi_nitrogen_dioxide";
    Variable[Variable["us_aqi_ozone"] = 100] = "us_aqi_ozone";
    Variable[Variable["us_aqi_sulphur_dioxide"] = 101] = "us_aqi_sulphur_dioxide";
    Variable[Variable["us_aqi_carbon_monoxide"] = 102] = "us_aqi_carbon_monoxide";
    Variable[Variable["sunshine_duration"] = 103] = "sunshine_duration";
    Variable[Variable["convective_inhibition"] = 104] = "convective_inhibition";
    Variable[Variable["shortwave_radiation_clear_sky"] = 105] = "shortwave_radiation_clear_sky";
    Variable[Variable["global_tilted_irradiance"] = 106] = "global_tilted_irradiance";
    Variable[Variable["global_tilted_irradiance_instant"] = 107] = "global_tilted_irradiance_instant";
})(Variable || (exports.Variable = Variable = {}));
